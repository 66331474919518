.plexo {
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    img {
      height: 60px;
      width: auto;
    }

    .amount {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media #{$mobile} {
        margin-top: 1rem;
      }

      p {
        font-size: 24px;
      }

      h1 {
        margin-top: 0;
        color: $primary;
        font-size: 64px;
      }
    }

    .logos {
      .socios-online {
        height: 90px;
      }

      .teleton {
        margin-top: 15px;
        height: 25px;
      }

      img {
        display: block;
        margin: auto;
      }
    }
  }

  iframe {
    max-width: 100%;
  }
}
