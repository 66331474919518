.login {
  margin: 0.6rem;

  .title {
    margin-top: 1rem;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }
}
