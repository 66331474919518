@import "../../node_modules/bulma/sass/utilities/initial-variables.sass";
@import "../../node_modules/bulma/sass/utilities/functions.sass";

@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

$red: #f11930;
$yellow: #ffc130;
$sky-blue: #0c9fe1;
$blue: #327cbc;
$green: #50a750;
$light-green: #52ce52;
$white: #ffffff;
$light-grey: #f5f5f5;
$grey: #bfbfbf;
$dark-grey: #707070;
$not-black: #232323;

$plexo-green: #cecf00;
$plexo-light-green: #dedf4a;
$plexo-blue: #004790;
$plexo-red: #ce3535;
$plexo-yellow: #e89b1b;

$primary: #f32735;
$primary-light: rgba(243, 39, 53, 0.4);
$transparent-primary: rgba($primary, 0.85);
$secondary: #f5525d;
$transparent-yellow: rgba(255, 193, 48, 0.2);
$input-border: rgba(0, 0, 0, 0.23);
$link: $yellow;
$link-invert: $white;

$mobile: "only screen and (max-width: 768px)";
$desktop: "only screen and (min-width: 769px)";

$header-height: 100px;
$header-content-height: 100px;
$white-line-height: 20px;
$footer-height: 60px;
$content-width: 1000px;

$family-sans-serif: "Nunito", sans-serif;
$input-border-color: #999999;
$input-focus-border-color: $primary;
$input-focus-box-shadow-color: rgba($primary, 0.25);

@import "../../node_modules/bulma/bulma.sass";
