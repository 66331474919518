@import "./variables";
@import "./animations";
@import "./common";
@import "./header";
@import "./landing";
@import "./plexo";
@import "./result";
@import "./login";
@import "./users";
@import "./configuration";

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  min-height: 100vh;
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  background-size: cover;

  .content {
    position: relative;
    margin: 0 auto;
    width: $content-width;
    max-width: 100%;
    background-color: $white;
    border-radius: 10px;
    padding: 2rem 3rem;

    @media #{$mobile} {
      padding: 16px;
      border-radius: 0;
      min-height: 0;
      min-height: 100vh;
    }
  }

  .login-content {
    width: 600px;
  }
}

.background-1 {
  background-image: url("../assets/background-1.jpg");
}

.background-2 {
  background-image: url("../assets/background-2.jpg");
}

.background-3 {
  background-image: url("../assets/background-3.jpg");
}

.background-4 {
  background-image: url("../assets/background-4.jpg");
}

.main::before {
  background-color: $transparent-primary;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}

.private-main {
  justify-content: flex-start;
  // background-image: none;

  .content {
    width: 100%;
    flex-grow: 1;
    border-radius: 0;
  }
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
