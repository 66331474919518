.users {
  .users-header {
    margin: 1rem 0 1.4rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      margin-bottom: 0;
    }
  }
}
