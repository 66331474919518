.result,
.thanks {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 60px;
    width: auto;
    margin-bottom: 1rem;
  }

  .socios-online {
    height: 90px;
  }

  .teleton {
    height: 25px;
  }

  .title {
    margin-top: 1rem;
    text-align: center;
    

    @media #{$mobile} {
      text-align: center;
    }
  }

  .plain-text {
    text-align: center;
  }

  .actions {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @media #{$mobile} {
      flex-direction: column-reverse;
      align-items: center;
    }

    button {
      margin: 0 0.6rem;
      width: 200px;
    }
  }

  .user-form {
    margin-top: 1rem;
    width: 100%;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-flow: wrap;

      @media #{$mobile} {
        flex-direction: column;
        align-items: center;
      }
    }

    .field {
      margin: 0.4rem;
      width: 40%;

      @media #{$mobile} {
        width: 100%;
      }

      .select,
      select {
        width: 100%;
      }
    }

    .form-actions {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        margin: 0.2rem;
        width: 200px;
      }
    }
  }
}

.thanks {
  .title {
    margin-top: 2rem;
  }

  .plain-text {
    margin: 1rem;
  }
}
