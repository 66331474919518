.header {
  box-sizing: border-box;
  height: $header-height;
  background-color: $primary;
  padding: 0 16px;
  z-index: 1;

  .header-content {
    height: $header-content-height;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $white;
    // width: $content-width;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 64px;

    @media #{$mobile} {
      padding: 0;
    }

    .left-side {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin-left: 32px;

        @media #{$mobile} {
          display: none;
        }
      }
    }

    img {
      height: 60px;
      cursor: pointer;
      user-select: none;

      @media #{$mobile} {
        height: 40px;
      }
    }

    span {
      font-size: 18px;

      @media #{$mobile} {
        font-size: 14px;
        margin-left: 32px;
        text-align: end;
      }
    }

    .header-options,
    .mobile-header-options {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media #{$mobile} {
        display: none;
      }

      .option {
        font-size: 16px;
        margin-left: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        color: $white;
        user-select: none;
        -webkit-transition: color 0.5s ease-in-out;
        transition: color 0.5s ease-in-out;
        color: $white;

        &:hover {
          color: $yellow;
        }

        .icon {
          margin-right: 4px;
          height: 20px;
          width: 20px;
        }
      }
    }

    .mobile-header {
      display: none;

      @media #{$mobile} {
        display: block;
      }

      .mobile-header-options {
        width: 100%;
        position: absolute;
        top: $header-content-height;
        left: 0;
        height: calc(100vh - #{$header-content-height});
        background-color: white;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        padding-top: 32px;
        border-radius: 30px 30px 0 0;

        .option {
          color: $red;
          margin: 16px 0;
          font-size: 16px;

          .icon {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
