.configuration {
  width: 800px;
  margin: 0 auto;
  max-width: 100%;

  .title {
    margin-top: 1rem;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }
}
