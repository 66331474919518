a {
  color: $link !important;
}

.notification {
  position: fixed;
  bottom: 1rem;
  min-width: 500px;
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
}
