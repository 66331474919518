.landing {
  .head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: auto;
    }

    .main-logo {
      height: 100px;
    }

    .secondary-logo {
      height: 25px;
      margin-top: 20px;
    }

    .title {
      margin-top: 2rem;
    }

    .subtitle {
      text-align: center;
    }
  }

  .plain-text {
    margin: 1rem 0 2rem 0;
  }

  .columns {
    margin-bottom: 0;

    @media #{$mobile} {
      margin: 1rem 0;
    }
  }

  .option {
    padding: 8px;
    box-sizing: border-box;

    @media #{$mobile} {
      margin: 0.4rem 2rem;
    }

    .option-content {
      border-radius: 16px;
      border: 2px solid $light-grey;
      -webkit-box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.2);
      -webkit-transition: all 0.6s ease-in-out;
      transition: all 0.6s ease-in-out;
      cursor: pointer;
      user-select: none;
      padding: 2px;
      display: flex;
      flex-direction: column;

      .donate {
        background-color: $primary;
        color: $white;
        font-size: 16px;
        border-radius: 12px 12px 0 0;
        text-align: center;
        padding: 4px 0;
      }

      p {
        text-align: center;
        color: $primary;
        margin: 0;
        font-size: 36px;
      }

      &:hover {
        border-color: $secondary;

        .donate {
          background-color: $secondary;
        }

        p {
          color: $secondary;
        }
      }
    }
  }

  .donation-option {
    font-size: 16px;
    color: $primary;
    text-align: center;
  }

  .view-more {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    div {
      display: flex;
      flex-grow: 1;
      height: 1px;
      background-color: $primary;
    }

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 1rem;
      color: $primary;
      cursor: pointer;
      user-select: none;
      font-size: 18px;
    }

    .icon {
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      margin-left: 0.8rem;
    }

    .rotate {
      transform: rotate(90deg);
    }
  }

  .payment-methods {
    padding-top: 15px;
    text-align: center;

    img {
      display: inline-block;
      height: 18px;
      padding: 0 8px;
      //filter: grayscale(1);
      //opacity: .7;

      @media #{$mobile} {
        height: 12px;
        padding: 0 5px;
      }
    }

    div {
      padding-bottom: 6px;
    }
  }
}
